/*******************
 Breadcrumb and page title
*******************/
@import "../../variable";

.page-breadcrumb {
  padding: 10px 20px;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-top: 1px solid rgba(0, 0, 0, 0.12);

  .page-title {
    margin-bottom: 0px;
  }

  .breadcrumb {
    padding: 0px;
    margin: 0px;
    background: transparent;
    font-size: 12px;

    .breadcrumb-item {
      position: relative;
      font-size: 14px;
      background-color: $secondary;
      margin: 0;

      a {
        color: #fff;
        background-color: $secondary;
        padding: 6px 5px 6px 0;
        display: inline-block;

        &:after {
          position: absolute;
          top: 0;
          left: 100%;
          z-index: 3;
          display: block;
          width: 0;
          height: 0;
          border-top: 17px solid transparent;
          border-bottom: 16px solid transparent;
          border-left: 10px solid $secondary;
          content: " ";
          background-color: transparent;
        }

        &:before {
          position: absolute;
          top: 0;
          left: 100%;
          z-index: 3;
          display: block;
          width: 0;
          height: 0;
          margin-left: 1px;
          border-top: 17px solid transparent;
          border-bottom: 16px solid transparent;
          border-left: 10px solid #fff;
          content: " ";
          background-color: transparent;
        }
      }

      span {
        background-color: $primary;
        padding: 6px 15px;
        display: inline-block;
        color: #fff;
      }

      &:last-child {
        background-color: $primary;
      }

      &:first-child {
        a {
          padding-left: 10px;
        }
      }

      & + .breadcrumb-item {
        span {
          padding: 6px 15px 6px 0px;
        }
      }
    }
  }
}
