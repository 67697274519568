/*
Template Name: Admin Template
Author: Niravjoshi / Wrappixel
File: scss
*/

//(fonts)
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800');

//(Required file)
@import 'variable';

//Theme colors
@import 'theme-colors/dark-theme';

// Import Bootstrap source files (Required file)
//@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "bootstrap/bootstrap";

//This is for the components (Required file)
@import 'components';

//This is for the pages (if you are not using some pages you can remove that style from pages folder)
@import 'pages';

//This is for the widgets (Required file)
@import 'widgets/widgets';

//This is for the horizontal version if you are not using horizontal version you can remove this
//@import 'horizontal/horizontal';

//This is for the responsive (Required file)
@import 'responsive';

//In This scss you can write your scss
@import 'custom';

//This is for the icons (Required file)
@import 'icons/font-awesome/css/fontawesome-all.css';
@import 'icons/simple-line-icons/css/simple-line-icons.css';
@import 'icons/weather-icons/css/weather-icons.min.css';
@import 'icons/themify-icons/themify-icons.css';
@import 'icons/flag-icon-css/flag-icon.min.css';
@import "icons/material-design-iconic-font/css/materialdesignicons.min.css";
@import "icons/crypto-icons/cryptocoins.css";



// this is for the perfect scrollbar

.left-sidebar {
  transition: none;
}

form .custom-card {
  background-color: #d7e7db;
}

.table-container {
  background-color: #d7e7db;
  float: left;
  width: 100%;
  flex: 1 1 auto;
  padding: 1.25rem;

  .table {
    background-color: $white;

    th {
      font-weight: 700 !important;
      padding: 0.15rem !important;
    }

    thead > tr:first-child th:nth-child(1) {
      width: 5%;
      text-align: left;
      padding-left: 15px !important;
    }

    thead > tr:first-child th:nth-child(2) {
      width: 5%;
      min-width: 0px !important;
    }

    thead > tr:first-child th:nth-child(3) {
      width: 47%;
    }

    thead > tr:first-child th:nth-child(4) {
      width: 20%;
    }

    thead > tr:first-child th:nth-child(5) {
      width: 15%;
      text-align: center;
      padding-left: 0 !important;
    }

    .dataCenter {
      text-align: center;
      padding-left: 5px 15px !important;
    }

    thead > tr:first-child th:nth-child(6) {
      width: 8%;
    }

    th, td {
      padding: 0.15rem;
      text-align: left;
      vertical-align: middle !important;
      padding-left: 15px !important;
      padding-right: 15px !important;

      input {
        width: 100%;
        border: none;
      }

      input {
        width: 100%;
        border: none;
      }
    }
  }
}

#basic-addon1, #basic-addon2 {
  width: 44px;
  height: 44px;
  text-align: center;
}

#basic-addon1 {
  padding: 0 14px;
}

#basic-addon2 {
  padding: 0 15px;
}

.link-style {
  color: #57a170 !important;

  &:hover, &:focus, &:active, &:active:focus {
    color: #57a170 !important;
    text-decoration: none !important;
    border-bottom: 1px solid #57a170;
    padding-bottom: 2px;
  }
}

.modal-backdrop, .modal {
  z-index: 99;
}

.mb-15 {
  margin-bottom: 15px;
}

.pb-0 {
  padding-bottom: 0;
}

.ag-fresh {
  .ag-root {
    border: 1px solid #3852ff;
  }

  .ag-header {
    height: 40px !important;
    background: $primary-light !important;
    color: #fff;
  }

  .ag-ltr {
    .ag-root {
      border: 1px solid $primary !important;
    }

    .ag-header-cell {
      padding-top: 10px;
      height: 40px !important;
      padding-left: 5px;
      border-right: 1px solid #ffffff !important;
      color: #fff;
    }

    .ag-cell-not-inline-editing,
    .ag-cell-no-focus,
    .ag-cell {
      border-top: 0;
      border-right: 1px solid #3852ff !important;
      border-bottom: 0;
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .ag-row-odd {
    background-color: rgba(56, 82, 255, 0.7) !important;
    color: #fff;
  }

  .ag-paging-button {
    padding: 3px 15px;
    background: $secondary;
    border: 0;
    color: #fff;
    border-radius: 2px;
    cursor: pointer;
    opacity: 1;

    &:disabled {
      background: #afaaaa;
      cursor: not-allowed;
    }
  }

  select {
    height: 25px;
    position: relative;
    top: 1px;
  }
}
// card-footer custome style
.card-footer {
  background-color: #fbfbfb;
  float: left;
  width: 100%;

  .form-group {
    label {
      float: left;
      width: 100%;
      padding-top: 10px;
      white-space: nowrap;
    }

    input {
      float: left;
      width: 100%;
    }

    .row {
      .col-md-2,
      .col-md-3 {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  .cardPagin {
    .pagination-label {
      float: left;
      width: auto;
      padding: 10px 8px 0;
    }

    select {
      float: left;
      width: 100px;
    }

    p {
      float: left;
      margin: 10px 10px 0;
      width: auto;
    }

    label {
      font-weight: normal;
    }
  }

  button {
    float: right;
  }

  button.save {
    margin-left: 15px;
  }

  .m-0 {
    margin: 0;
  }

  .p-0 {
    padding: 0;
  }

  button.saveDial {
    margin-right: 10px !important;
  }


  @media all and (max-width:1366px) {
    select {
      width: 80px !important;
    }

    p {
      margin-right: 10px !important;
    }
  }

  .tab-content .tab-pane {
    padding-top: 20px;
  }

  .alert {
    padding: 8px;
    margin-bottom: 8px;
  }

  .ng-valid[required], .ng-valid.required {
    border-left: 5px solid #42A948; /* green */
  }

  .ng-invalid:not(form) {
    border-left: 5px solid #a94442; /* red */
  }
}

@media all and (min-width:1169px) {
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"] .topbar .top-navbar .navbar-header .logo-text {
    display: block;
  }
}

@media all and (max-width:1169px) {
  form .form-group label {
    height: auto;
  }
}

@media all and (min-width:768px) {
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"] .topbar .top-navbar .navbar-header .logo-text {
    display: block;
  }
}

.bg-cover {
  background-image: url('../images/big/auth-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
}

body, html {
  width: 100%;
  height: 100%;
  min-height: 100%;
  position: relative;
}

.min-height-100 {
  min-height: 100%;
}

.logo {
  background-color: #fff;
  border: 1px solid $primary;
  text-align: center;
  margin-bottom: 15px;
  width: 100%;
  overflow: hidden;
  float: left;

  .img-fluid {
    padding: 50px 0;
    width: 100%;
    float: left;

    img {
      max-width: 100%;
    }
  }

  h5 {
    background-color: $primary;
    background-color: #57a170;
    padding: 17px 0 15px;
    color: #ffffff;
    margin-bottom: 0;
    width: 100%;
    font-size: .875rem;
    float: left;
    font-weight: 400;
  }
}

.auth-panel, .auth-box {
  padding-bottom: 15px;
  //margin-bottom: 15px;
  //border-bottom: 1px solid $primary;

  .form-horizontal {
    .form-group {
      margin-bottom: 15px;

      label {
        margin-bottom: 0;
      }

      .form-control {
        font-size: 14px;
        float: none;
      }

      .required-denote {
        left: 3px;
        position: relative;
      }

      a {
        &:hover {
          text-decoration: underline;
        }
      }

      .btn {
        border-radius: 0;
        font-size: .875rem;
        font-weight: 400;
        padding: .532rem 1.5rem;
        margin-top: 15px;
        height: 46px;
      }
    }

    .input-group {
      margin-bottom: 15px;

      .form-control {
        font-size: 14px;
      }
    }

    .form-control-feedback {
      font-size: 12px;
    }
  }
}

.show-pass {
  position: absolute;
  right: 1px;
  top: 1px;
  z-index: 99;
  padding: 12px 10px;
  margin: 0;
  width: auto !important;
  font-size: 14px !important;
  text-decoration: underline !important;
  vertical-align: middle;
  height: 40px;
  background: transparent;
  cursor: pointer;
}

.password-hint {
  padding: 15px;
  border: 1px solid #CCC;
  background-color: white;
  color: grey;
  position: relative;
  margin-top: 13px;
}

.password-hint li {
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-size: 13px;
  margin-bottom: 7px;
  list-style: none;
  display: block;
}

.password-hint li:before {
  font: normal normal normal 14px/1 FontAwesome;
  margin-right: 3px;
  font-weight: normal;
}

.password-hint:after, .password-hint:before {
  position: absolute;
  content: "";
  left: 7px;
  border-width: 10px;
  border-style: solid;
}

.password-hint:after {
  border-color: transparent transparent white transparent;
  top: -19px;
  z-index: 99;
}

.password-hint:before {
  border-color: transparent transparent #CCC transparent;
  top: -21px;
}

.password-hint .fa {
  color: green;
  margin-right: 5px;
}

.password-hint strong {
  margin-left: 5px;
}

.password-hint .fa-times {
  color: red;
}

.card-footer .btn-primary {
  padding: 0.53rem 0.905rem;
}

ul.row {
  li {
    list-style: none !important;

    .custom-switch {
      float: right;
    }
  }
}

.main-heading {
  display: inline-block;
  width: auto;
  padding: 14px 0 0;
  height: 58px;

  h2 {
    margin-bottom: 0;
  }
}

.backdrop {
  position: fixed;
}

.ml-15 {
  margin-left: 15px !important;
}

.table-container {
  table {
    .custom-switch {
      margin: 3px 0;
    }
  }
}

.mh-58 {
  min-height: 58px;
}

/// dashboard
.bg {
  background-color: #d7e7db;
}

.info-panel {
  float: left;
  width: 100%;
  padding: 30px;
  background-color: $white;
  text-align: center;
  height: 465px;

  h5 {
    float: left;
    width: 100%;
    text-align: center;
  }
}

.countDownPanel {
  text-align: center;

  .countDown {
    width: 288px;
    height: 288px;
    border-radius: 50%;
    border: 10px solid #ebf3ed;
    padding: 40px;
    background-color: #d7e7db;
    display: inline-table;
    margin-bottom: 25px;
    margin-top: 27px;

    h1 {
      color: $primary;
      display: table-cell;
      vertical-align: middle;
    }
  }
}

button.info-panelBtn {
  float: left;
  width: 100%;
  margin-top: 20px;
  height: 60px;
  font-size: 14px;
  font-weight: 600;
}

button.btn.btn-primary.notComplete {
  background-color: $red;
  border-color: $red;
}

button.btn.btn-primary.pageNotComplete {
  background-color: $red;
  border-color: $red;
  cursor: default;
}

button.btn.btn-primary.pageNotComplete:focus, button.btn.btn-primary.pageNotComplete:active, button.btn.btn-primary.pageNotComplete:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(255,0,0, 0.5);
  background-color: #b00b0b !important;
  border-color: #b00b0b !important;
}

button.btn.btn-primary.pageNotComplete:hover {
  background-color: #b00b0b;
  border-color: #b00b0b;
}


button.btn.btn-primary.pageComplete {
  cursor: default;
}

button.btn.btn-primary.notComplete:focus, button.btn.btn-primary.notComplete:active, button.btn.btn-primary.notComplete:active:focus {
  box-shadow: 0 0 0 0.2rem rgba(255,0,0, 0.5);
  background-color: #b00b0b !important;
  border-color: #b00b0b !important;
}

button.btn.btn-primary.notComplete:hover {
  background-color: #b00b0b;
  border-color: #b00b0b;
}

.pageInfoTable thead > tr:nth-child(2) th:nth-child(1) {
  width: 8%;
}

.pageInfoTable thead > tr:nth-child(2) th:nth-child(2) {
  width: 82%;
}

.pageInfoTable thead > tr:nth-child(2) th:nth-child(3) {
  width: 10%;
}

.pageInfoTable {
  button {
    width: 100%;
  }
}

/// page 5
.orderHeader {
  float: left;
  width: 100%;
  margin-top: 20px;

  h3 {
    background-color: $primary;
    color: $white;
    float: left;
    width: 100%;
    text-align: center;
    padding: 10px;
  }
}

.order-1 .row ul, .order-2 .row ul {
  float: left;
  width: 100%;

  li {
    list-style: none;
    padding: 0;

    .justify-content-between {
      padding: 0 15px !important;
      height: auto;
      min-height: inherit;


      label {
        margin-bottom: 0;
        margin-top: 18px;
      }

      .custom-switch {
        float: right;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

.warning-bg {
  background-color: #f0aec2 !important;
}

.info-bg {
  background-color: #cffcfc !important;
}

.alert-bg {
  background-color: #fdfea9 !important;
}

.fixed-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  background-color: #ffffff;
  margin-top: 30px;

  thead {
    width: 100%;

    tr {
      display: table;
      position: relative;
      width: 100%;

      th {
        font-weight: 700;
      }

      &:first-child {
        border-bottom: 0;
      }

      &:last-child {
        border-bottom: 0;
        border-top: 0;

        th {
          border-top: 0;

          &:first-child {
            width: 120px !important;
            text-align: center;
          }

          &:last-child {
            width: 137px;
          }
        }
      }
    }
  }

  tbody {
    display: block;
    overflow: auto;
    width: 100%;
    height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;

    tr {
      display: table;
      position: relative;
      width: 100%;

      td {

        &:first-child,
        &:last-child {
          width: 120px !important;
          text-align: center;
        }
      }
    }
  }
}


.dashboard-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  margin-top: 30px;

  thead {
    tr {
      th {
        font-weight: 700;
      }

      &:last-child {
        th {
          border: 1px solid #dee2e6;

          &:first-child {
            width: 137px;
            text-align: center;
            padding-left: 5px;
            padding-right: 5px;
          }

          &:last-child {
            width: 137px;
            text-align: center;
            padding-left: 5px;
            padding-right: 5px;
          }
        }
      }
    }
  }

  tbody {
    tr {
      td {
        border: 1px solid #dee2e6;
        padding: 5px;
        vertical-align: middle;

        button {
          padding: 5px 25px;
          font-weight: bold;
        }

        &:first-child {
          text-align: center;
        }

        &:last-child {
          text-align: center;
        }
      }
    }
  }
}

.switch-label {
  float: none !important;
  width: auto !important;
  display: inline-block !important;
}

.left-0 {
  left: 0 !important;
}

.pl-15 {
  padding-left: 15px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 2px !important;
}

textarea[rows="6"] {
  height: 152px !important;
}

.total-page-2 {
  padding-top: 7px;
  border-top: 1px solid #6e9bd5;
  position: relative;
  top: -8px;
}

.mn-w-170 {
  min-width: 170px;
  max-width: 100%;
}

text.highcharts-credits {
  display: none;
}

.btn-xl {
  padding: 20px 10px;
  font-weight: bold;
}


.textarea-form {
  position: relative;

  .textarea-tooltip {
    bottom: -9px;
    position: absolute;
    display: block;
    left: 0;
    min-height: 22px;
    top: auto;
  }
}

.form-card, .custom-card {
  .form-group, .list-group-item, .form-check {
    label {
      font-weight: normal !important;
    }
  }
}

label {
  font-weight: normal !important;
}

.invalid-tooltip {
  background-color: #f62d51;
  white-space: nowrap;
  max-width: fit-content;
  min-width: 125px;
  left: 10px;
}

.ng-dropdown-panel + .invalid-tooltip,
.input-group .invalid-tooltip,
.textarea-form .invalid-tooltip {
  left: 0;
}

.ng-invalid .invalid-tooltip.ng-star-inserted {
  display: block;
}


.card-primary {
  .card-header {
    background-color: #57a170;
    color: #fff;
  }

  .card-body {
    background-color: #d7e7db;

    .form-group {
      label {
        margin-bottom: 0;
      }
    }
  }

  .card-footer {
    padding-top: 10px;

    .required {
      margin-bottom: 5px;
      text-align: left;
      font-size: 12px;
    }
  }
}

.textarea-control {
  display: block;
  width: 100%;
  border-radius: 0;
  border: 1px solid #d6d6d6;
}

textarea[readonly] {
  border-color: #c5c5c5;
  border-radius: 0;
}

.topbar {
  .navbar-header {
    position: relative !important;

    .logo-text {
      width: 250px;
      transition: all 0.15s ease-out;
      overflow: hidden;
    }
  }

  .navbar-collapse.collapse {
    margin-left: 0 !important;
  }
}

.block-panel {
  float: left;
  width: 100%;
}

.card-footer {
  .text-right {
    .btn {
      margin-left: 10px;
    }
  }
}

.mh-208 {
  min-height: 208px;
}

ngb-modal-backdrop.modal-backdrop.fade.show {
    z-index : 60 !important
}
