// Responsive
/*
Template Name: Admin Template
Author: Wrappixel

File: scss
*/

@media (min-width: 1861px) {
  .total-page-2 .control-label {
    white-space: nowrap;
  }

  .height-100 {
    min-height: 932px;
  }
}


@media (max-width: 1530px) and (min-width: 1290px) {
  .card-footer .cardPagin p {
    width: 0 !important;
    opacity: 0;
    overflow: hidden;
    height: 1px;
  }

  .custom-card .card-footer select {
    width: 60px !important;
    padding: 0.375rem 0.6rem;
  }
}

@media (min-width: 991px) and (max-width: 1289px) {
  .card-footer .text-right .btn {
    padding: 0.53rem 0.6rem;
  }
}

@media (min-width: 991px) and (max-width: 1289px) {
  .card-footer .cardPagin p,
  .card-footer .cardPagin .pagination-label {
    width: 0 !important;
    opacity: 0;
    overflow: hidden;
    height: 1px;
  }

  .custom-card .card-footer select {
    width: 60px !important;
    padding: 0.375rem 0.6rem;
  }
}

@media (max-width: 1860px) and (min-width: 1600px) {

  .lg-w-67 {
    flex: 0 0 67%;
    max-width: 67%;
  }

  .lg-w-33 {
    flex: 0 0 33%;
    max-width: 33%;
  }

  .lg-ml-0 {
    margin-left: 0;
  }

  .lg-w-100 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .total-page-2 .control-label {
    max-width: 100px;
  }

  .height-100 {
    min-height: 932px;
  }
}

@include media-breakpoint-down(lg) {
  .p-5 {
    padding: 1.5rem !important;
  }
}

/*-- ============================================================== 
 Ipad & above all(768px) 
 ============================================================== */
@include media-breakpoint-up(md) {
  .bc-content {
    justify-content: flex-end;
  }
}

/*-- ============================================================== 
 Medium Screen(1168px) 
 ============================================================== */
@media (max-width: 1168px) and (min-width: 320px) {
  .page-wrapper {
    .card-footer {
      padding: 40px 30px 20px 110px;
    }
  }

  .page-wrapper {
    margin-bottom: 160px;
  }
}

/*-- ============================================================== 
 Small Screen(1168px) 
 ============================================================== */
@include media-breakpoint-down(md) {
  .card-footer {
    .form-group {
      .row {
        .col-md-2, .col-md-3 {
          padding-left: 15px !important;
        }
      }
    }
  }

  .hidden-md-down {
    display: none;
  }

  .do-block {
    display: block !important;
  }

  .md-mb-15 {
    margin-bottom: 15px;
  }

  .md-pl-15 {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 1800px) {
  .registered-text {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .registered-text {
    font-size: 16px !important;
  }
}

/*-- ============================================================== 
 Small Screen(991px) 
 ============================================================== */
@media only screen and (max-width: 991px) {
  .page-wrapper {
    margin-bottom: 160px;
  }

  .registered-text {
    font-size: 18px !important;
  }

  .total-page-3 {
    margin-top: 101px;
    border-top: 1px solid #6e9bd5;
    padding-top: 10px;
  }

  .form-card {
    .align-items-start {
      .form-group {
        .control-label {
          display: block;
          height: auto;
        }
      }
    }
  }

  .total-page-3 {
    margin-top: 30px;
    border-top: 1px solid #6e9bd5;
    padding-top: 10px;
  }
}

/*-- ============================================================== 
 Phone and below ipad(768px) 
 ============================================================== */
@media only screen and (max-width: 768px) {
  #main-wrapper[data-layout="vertical"][data-sidebartype="full"] {
    .cardPagin {
      max-width: 100%;
      flex: 0 0 100%;
      text-align: center;

      * {
        float: none !important;
        display: inline-block;
        margin-top: 0 !important;
      }

      & + .col-12 {
        max-width: 100%;
        flex: 0 0 100%;
        text-align: center;

        * {
          margin-top: 0 !important;
        }
      }
    }
  }
}

/*-- ============================================================== 
 Phone and below ipad(767px)
 ============================================================== */
@media (max-width: 767px) and (min-width: 320px) {
  .total-page-2 {
    padding-top: 7px;
    border-top: 1px solid #6e9bd5;
    position: relative;
    top: 0;
    margin-top: 32px;
  }

  .card-footer {
    .form-group {
      .row {
        .col-md-2, .col-md-3 {
          padding-left: 10px !important;
        }
      }
    }
  }

  .page-wrapper {
    .card-footer {
      padding: 10px 20px 0px 88px;
    }
  }

  .cardPagin {
    p, label {
      display: none !important;
    }

    select {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .page-wrapper {
    margin-bottom: 150px;
  }
}

/*-- ============================================================== 
 Phone and below ipad(767px)
 ============================================================== */
@include media-breakpoint-up(sm) {
  #main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"] .left-sidebar {
    width: 65px;
  }

  .topbar .top-navbar .navbar-nav > .nav-item > .nav-link {
    padding: 0px 14px;
    font-size: 0.875rem;
    line-height: 76px;
    height: 76px;
  }

  .custom-table {
    table {
      thead {
        tr {
          th {
            white-space: nowrap;
            min-width: 100px;

            &[colspan="2"] {
              width: 200px;
            }
          }

          &:first-child {
            th {
              &:first-child {
                min-width: 60px;
              }

              &:nth-child(2) {
                min-width: 250px;
              }
            }
          }
        }
      }
    }
  }

  .table-container {
    table {
      thead {
        tr {
          th {
            white-space: nowrap;
            min-width: 130px;

            &[colspan="2"] {
              width: 200px;
              padding: 3px 15px;
            }
          }

          &:first-child {
            th, th[colspan="2"] {
              padding: 3px 15px;

              &:first-child {
                min-width: 40px;
              }

              &:nth-child(2) {
                min-width: 250px;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {

  .custom-table {
    table {
      thead {
        tr {
          th {
            white-space: nowrap;
            min-width: 100px;

            &[colspan="2"] {
              width: 200px;
            }
          }

          &:first-child {
            th {
              &:first-child {
                min-width: 60px;
              }

              &:nth-child(2) {
                min-width: 250px;
              }
            }
          }
        }
      }
    }
  }

  .table-container {
    table {
      thead {
        tr {
          th {
            white-space: nowrap;
            min-width: 130px;

            &[colspan="2"] {
              width: 200px;
              padding: 3px 15px;
            }
          }

          &:first-child {
            th, th[colspan="2"] {
              padding: 3px 15px;

              &:first-child {
                min-width: 40px;
              }

              &:nth-child(2) {
                min-width: 250px;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .table-container {
    table {
      thead {
        tr {
          th {
            white-space: nowrap;
            min-width: 100px;

            &[colspan="2"] {
              width: 150px;
              padding: 3px 15px;
            }
          }

          &:first-child {
            th {
              padding: 3px 10px !important;

              &:first-child {
                min-width: 40px !important;
                padding: 3px 5px !important;
                text-align: center;
              }
            }

            th[colspan="2"] {
              padding: 0px 10px !important;

              &:nth-child(2) {
                min-width: 200px;
              }
            }
          }
        }
      }

      tbody {
        tr {
          td {
            padding-left: 10px !important;
            padding-right: 10px !important;

            &:first-child {
              padding-left: 0 !important;
              padding-left: 0 !important;
            }
          }
        }
      }
    }
  }

  .custom-table {
    table {
      thead {
        tr {
          th {
            white-space: nowrap;
            min-width: 80px;
            font-weight: 700;

            &[colspan="2"] {
              width: 160px;
            }
          }

          &:first-child {
            th {
              &:first-child {
                min-width: 40px;
              }

              &:nth-child(2) {
                min-width: 200px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 766px) {
  .navbar-header {
    a {
      display: none !important;
    }

    .navbar-brand {
      display: block !important;

      .logo-text {
        display: block !important;
      }
    }
  }
}

@media (max-width:576px) {
  .page-wrapper {
    margin-bottom: 289px !important;
  }
}

@media (max-width: 480px) {
  .main-heading h2 {
    font-size: 22px;
    padding-top: 4px;
  }
}
