﻿//Buttons
@import "../../variable";
.btn-circle {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 10px;

  &.btn-sm {
    width: 35px;
    height: 35px;
    padding: 8px 10px;
    font-size: 14px;
  }

  &.btn-lg {
    width: 50px;
    height: 50px;
    padding: 14px 15px;
    font-size: 18px;
    line-height: 23px;
  }

  &.btn-xl {
    width: 70px;
    height: 70px;
    padding: 14px 15px;
    font-size: 24px;
  }
}

.btn-xs {
  padding: .25rem .5rem;
  font-size: 10px;
}

.btn-rounded {
  border-radius: 60px;
  padding: 7px 18px;

  &.btn-lg {
    padding: .75rem 1.5rem;
  }

  &.btn-sm {
    padding: .25rem .5rem;
    font-size: 12px;
  }

  &.btn-xs {
    padding: .25rem .5rem;
    font-size: 10px;
  }

  &.btn-md {
    padding: 12px 35px;
    font-size: 16px;
  }
}


.btn-primary, .btn-default, .btn-warning, .btn-outline-primary:hover, .btn-outline-warning:hover {
  color: $white;

  &:hover {
    color: $white;
  }
}

.btn-default {
  background-color: $primary;
}


.btn-facebook {
  color: $white;
  background-color: #3b5998;

  &:hover {
    color: $white;
  }
}

.btn-twitter {
  color: $white;
  background-color: #55acee;

  &:hover {
    color: $white;
  }
}

.btn-linkedin {
  color: $white;
  background-color: #007bb6;

  &:hover {
    color: $white;
  }
}

.btn-dribbble {
  color: $white;
  background-color: #ea4c89;

  &:hover {
    color: $white;
  }
}

.btn-googleplus {
  color: $white;
  background-color: #dd4b39;

  &:hover {
    color: $white;
  }
}

.btn-instagram {
  color: $white;
  background-color: #3f729b;
}

.btn-pinterest {
  color: $white;
  background-color: #cb2027;
}

.btn-dropbox {
  color: $white;
  background-color: #007ee5;
}

.btn-flickr {
  color: $white;
  background-color: #ff0084;
}

.btn-tumblr {
  color: $white;
  background-color: #32506d;
}

.btn-skype {
  color: $white;
  background-color: #00aff0;
}

.btn-youtube {
  color: $white;
  background-color: #bb0000;
}

.btn-github {
  color: $white;
  background-color: #171515;
}

.button-group {
  .btn {
    margin-bottom: 5px;
    margin-right: 5px;
  }
}

.no-button-group .btn {
  margin-bottom: 5px;
  margin-right: 0px;
}

.btn .text-active {
  display: none;
}

.btn.active .text-active {
  display: inline-block;
}

.btn.active .text {
  display: none;
}


/*  Custom button Style  */
.btn-layout {
  background-color: $skin1;
  border-color: $skin1;
  color: $white;

  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(41, 98, 255, 0.5);
  }

  &:hover {
    background-color: $skin1hover;
  }
}

/*  button addon Style  */


.btn-info {
  background-color: $brand-info;
  border-color: $brand-info;
  padding: 0.53rem 0.905rem;

  &:hover, &:focus, &:hover:focus, &:active {
    background-color: $brand-info-hover;
    border-color: $brand-info-hover;
    box-shadow: none;
  }

  &:not([disabled]):not(.disabled):active,
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: $info-shadow;
    background-color: $brand-info;
    border-color: $brand-info;
  }
}


.btn-addon {
  position: relative;
  padding-left: 35px;
  border-radius: 0;
  border: 0;

  &:hover {
    span {
      border-right: 1px solid $skin1;
    }
  }

  > span {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 27px;
    background-color: $skin1hover;
    border-right: 1px solid $skin1hover;
    padding: 7px 0 0;
  }
}
