// In this scss you can overwrite all our scss, we suggested you to add your scss into this, so whenever the update comes it will not affect your code.

/*(Required file)*/
@import 'variable';


.mb-20 {
  margin-bottom: 20px !important;
}

.form-control-feedback {
  float: left;
  width: 100%;
  color: $red;
}

.form-card .card-block {
  padding: 15px;
}

.row [class*="col-"]:nth-of-type(2n+1) .form-card .card-header {
  background-color: $green;
  color: $white;
}

.row [class*="col-"]:nth-of-type(2n+2) .form-card .card-header {
  background-color: $indigo;
  color: $white;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #57a170;
  content: "";
}

/*  Custom card Style  */
.custom-card {
  box-shadow: none;

  .card-header {
    background-color: $white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    .card-title {
      color: $skin1hover;
      font-size: 24px;
    }
  }

  .card-body {
    .material {
      box-shadow: none;
      border: 1px solid rgba(0, 0, 0, 0.12);
    }
  }

  .card-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.12);

    .custom-btn-default {
      background-color: transparent;
      color: $skin1;
      padding: 0;
      margin-right: 10px;

      &:hover {
        color: $skin1hover;
      }

      .fa {
        margin-right: 5px;
        display: inline-block;
      }
    }

    &.text-right {
      .btn {
        margin-left: 10px;
        margin-right: 0;
      }
    }
  }
}

.pl0 {
  padding-left: 0 !important;
}
/*  input-group-prepend  */
.input-group {
  .input-group-prepend {
    .btn + .btn {
      border-left: 1px solid $white;
      color: $white;
    }
  }
}

.ngx-datatable.material.single-selection .datatable-body-row.active,
.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group {
  background-color: #eee !important;
}

.ngx-datatable .datatable-body-cell {
  cursor: pointer;
}

.form-control {

  &.is-invalid, &.ng-dirty.ng-invalid {
    border: 1px solid #f62d51 !important;
  }

  &.ng-dirty.ng-touched.ng-valid {
    border: 1px solid #36bea6 !important;
  }
}

ng-select {
  &.is-invalid, &.ng-dirty.ng-invalid {
    .ng-select-container {
      border-color: #f62d51;
    }
  }

  &.ng-dirty.ng-touched.ng-valid {
    .ng-select-container {
      border-color: #36bea6;
    }
  }
}

.is-invalid, .ng-dirty {
  & ~ .invalid-feedback {
    display: block;
  }
}

.custom-text {
  font-size: 11px;
}


.custom-tabs {
  background-color: transparent;
  margin-bottom: 20px;
  border: 0;

  .nav-item {

    .nav-link {
      background-color: transparent;
      border: 0;
      box-shadow: none;
      border-bottom: 2px solid transparent;
      color: $skin1;
      font-size: 16px;

      &.active {
        background-color: transparent;
        border-bottom: 2px solid $skin1;
        color: $skin1;
      }
    }
  }
}

label.checkbox-label {
  height: 22px;
  display: inline-block;
}

.price-table {
  width: 100%;

  tr {
    border-bottom: 1px solid #ddd;

    td {
      padding: 10px;

      &:last-child {
        text-align: right;
      }
    }
  }
}

.product-images {
  width: 100%;

  .sortable-container {
    width: 100% !important;
    border: 0 !important;

    .sortable-header {
      display: none !important;
    }

    .sortable-list {
      height: auto !important;
      border: 0;
      border-radius: 0;

      li {
        width: 33.33%;
        display: block;
        float: left;
        overflow: hidden;
        margin-bottom: 15px;
        padding: 0 5px;

        .delete-btn {
          background: #fff;
          text-align: right;
          padding: 5px 0;
        }

        background: #fff;

        i {
          color: #fff;
          font-size: 14px;
          background: red;
          padding: 3px 5px;
          border-radius: 50%;
        }
      }
    }
  }
}

.justify-content-start {
  background-color: transparent;
  margin-bottom: 20px;
  border: 0;

  .nav-item {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center;

    a.nav-link {
      background-color: transparent;
      border: 0;
      box-shadow: none;
      border-bottom: 2px solid transparent;
      color: $skin1;
      font-size: 16px;

      &.active {
        background-color: transparent;
        border-bottom: 2px solid $skin1;
        color: $skin1 !important;
      }
    }
  }
}

.form-card, .custom-card {
  .form-group, .list-group-item, .form-check {
    label {
      font-weight: normal !important;
    }
  }
}

label {
  font-weight: normal !important;
}

.confirm-close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: white;
}

.page {
  font: 11pt 'Arial' !important;
  width: 297mm;
  height: 209.5mm;
  padding: 1mm;
  margin: 10mm auto;
  border: 1px #D3D3D3 solid;
  border-radius: 5px;
  background: #d7e7db;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  position: relative !important;

  &:after {
    content: "";
    position: absolute;
    width: 297mm;
    height: 209.5mm;
    top: 0;
    left: 0;
    z-index: 9999;
  }

  .card-footer {
    padding: 10px 15px !important;
    margin: 0px !important;
  }

  .page-breadcrumb {
    margin-bottom: 10px;
  }

  .total-page-3 {
    border-top: 1px solid #6e9bd5;
    padding-top: 15px;
  }

  &.page-4 {
    .form-group {
      margin-bottom: 0;
    }

    .page-4-list {
      margin-bottom: 0 !important;

      li {
        .justify-content-between {
          min-height: 1px;

          .required-denote {
            margin-top: 0;
            margin-bottom: 2px;
          }
        }
      }
    }
  }

  &.page-14 {

    .table-container {
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;

      .table {
        margin-bottom: 0;

        th, td {
          padding: 0;

          .custom-switch {
            margin: 0;
          }
        }
      }
    }
  }

  &.page-52 {
    label {
      font-size: 10pt;
    }

    .form-group {
      margin-bottom: 5px;

      label {
        height: auto;
        display: block;
        font-size: 10pt;
      }

      .form-control {
        height: 30px;
        min-height: 1px;
      }

      .input-group-text {
        padding-top: 0;
        padding-bottom: 0;
      }

      .total-page-3 {
        margin-top: 0;
        padding-top: 5px;
      }

      .ng-select {
        &.ng-select-single {
          .ng-select-container {
            height: 30px;
            min-height: 1px;
          }
        }
      }
    }
  }

  .custom-card {
    position: static;

    .card-body {
      padding: 0 1.25rem;
    }

    .card-footer {
      border-top: 1px solid rgba(0,0,0,.12);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      .form-group {
        margin-bottom: 0px;
      }
    }
  }

  .col-print-1 {
    width: 8%;
    float: left;
    display: block;
  }

  .col-print-2 {
    width: 16%;
    float: left;
    display: block;
  }

  .col-print-3 {
    width: 25%;
    float: left;
    display: block;
  }

  .col-print-4 {
    width: 33%;
    float: left;
    display: block;
  }

  .col-print-5 {
    width: 42%;
    float: left;
    display: block;
  }

  .col-print-6 {
    width: 50%;
    float: left;
    display: block;
  }

  .col-print-7 {
    width: 58%;
    float: left;
    display: block;
  }

  .col-print-8 {
    width: 66%;
    float: left;
    display: block;
  }

  .col-print-9 {
    width: 75%;
    float: left;
    display: block;
  }

  .col-print-10 {
    width: 83%;
    float: left;
    display: block;
  }

  .col-print-11 {
    width: 92%;
    float: left;
    display: block;
  }

  .col-print-12 {
    width: 100%;
    float: left;
    display: block;
  }
}

form {
  .page-3 {
    .card-body {
      .form-group {
        margin-bottom: -8px !important;

        label {
          height: 36px;
        }

        .form-control {
          height: 25px;
        }

        .input-group-text {
          padding-top: 0;
          padding-bottom: 0;
        }

        .total-page-3 {
          margin-top: 0;
          padding-top: 5px;
        }
      }
    }
  }
}

@page {
  size: A4 landscape;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

@media print {
  html, body {
    margin: 0;
    padding: 0;
    font: 11pt 'Arial';
    height: 100%;
    background: #d7e7db;
  }

  .page {
    margin: 0;
    padding: 0;
    border: initial;
    width: initial;
    height: 209.5mm;
    border-radius: initial;
    background: #d7e7db;
    box-shadow: initial;
    overflow: initial;
    page-break-after: always;
  }

  #back, header, aside, app-breadcrumb, footer.footer, ngx-loading-bar {
    display: none !important;
  }

  .page-wrapper {
    margin: 0px !important;
    padding: 0px !important
  }

  .container-fluid {
    padding: 0px !important;
  }

  .table-primary, .custom-table {
    thead {
      background-color: #57a170 !important;
      color: #fff;

      tr {
        background-color: #57a170 !important;
        color: #fff;

        th {
          background-color: #57a170 !important;
          color: #fff;
        }
      }
    }
  }
}

.registered-text {
  font-size: 21px;
  font-weight: bold;
}

.dark-logo.img-fluid {
  width: 40px !important;
  max-width: 40px !important;
}

.light-logo.img-fluid {
  max-width: 200px;
}

.toast-container {

  .toast-warning {
    background-color: #F69224;

    .toast-title {
      text-align: center;
    }

    .toast-close-button {
      color: $white;
      cursor: pointer;
      background: transparent;
      border: 0;
      top: 4px;
      right: 0;
      font-size: 13px;
      font-weight: normal;

      &:hover, &:focus, &:active, &:focus:active {
        color: $white;
        outline: none;
        opacity: 1;
      }
    }

    .toast-message {
      padding-top: 15px;
      padding-bottom: 15px;

      .error-message {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .btn-info-circle {
        border: 0;
        background-color: transparent;
        padding: 0;
        float: right;
        color: $white;

        &:hover, &:focus, &:active, &:focus:active {
          color: $white;
          outline: none;
          opacity: 1;
        }
      }

      .srcipt-error {
        padding: 10px;
        color: $white;
        background-color: #FF0000;
      }

      .app-error {
        padding: 10px;
        color: $black;
        background-color: #FFAA4D;
      }
    }
  }

  .toast-success {
    background-size: 20px !important;

    .toast-close-button {
      top: 4px;
      right: 0;
      font-size: 13px;
      font-weight: normal;
    }

    .toast-message {
      margin-top: 3px;
      padding-right: 20px;
    }
  }

  .toast-error {
    background-size: 20px !important;

    .toast-close-button {
      top: 0px;
    }

    .toast-message {
      margin-top: 5px;
    }
  }
}

input[type=checkbox] {
  padding: 8px;
  height: 20px;
  width: 20px;
}


.btn-icon {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

.input-height {
  height: 44px;
}

.register-link {
  border-top: 1px solid $primary;
}

.register-link .text-center {
  margin-top: 15px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.btn-block-block {
  display: block;
  width: 100%;
}

.btn-container-phone {
  gap: 10px;
}

.policy-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.flex-column {
  flex-direction: column;
}

a {
  cursor: pointer !important;
}

.modal-sm {
  max-width: 400px;
}

.input-group-prepend.height-40 + .invalid-tooltip {
  right: 0;
  left: auto;
}

.dropdown-menu {
  .ngb-dp-header {
    background-color: #f8f9fa;

    ngb-datepicker-navigation-select {
      > .form-select {
        border-color: rgba(0, 0, 0, 0.12);
      }
    }
  }

  .ngb-dp-months {
    .ngb-dp-weekdays {
      background-color: #f8f9fa;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      margin-bottom: 5px;

      .ngb-dp-day, .ngb-dp-weekday, .ngb-dp-week-number {
        color: $primary
      }
    }

    .btn-light {
      &:hover, &:focus, &:active, &:focus:active {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
  }
}

text.highcharts-title {
  transform: translate(0px, 50px);
}
