// App
@import "../../variable";
/*============================================================== 
 For all pages 
 ============================================================== */

#main-wrapper {
  width: 100%;
  overflow: hidden;

  &[data-sidebartype="mini-sidebar"] {
    header {
      .light-logo {
        display: none;
      }

      .dark-logo {
        display: block;
      }
    }
  }
}

.page-wrapper {
  background: $body-bg;
  position: relative;
}

.page-wrapper > .container-fluid {
  padding: 20px;
  min-height: calc(100vh - 180px);
}

/*******************
 Footer
*******************/

.footer {
  padding: 15px 20px;
}

/*******************
 Stickey inner-left-part
*******************/

$left-part-width: 260px;

.left-part {
  height: 100%;
  width: $left-part-width;
  position: absolute;
  border-right: 1px solid $border-color;

  &.fixed-left-part {
    position: fixed;
    top: 0px;
    padding-top: 10px;
  }

  .show-left-part {
    position: absolute;
    top: 45%;
    right: -41px;
  }
}

.right-part {
  width: calc(100% - 260px);
  min-height: calc(100vh - 64px);
  overflow: auto;
  margin-left: $left-part-width;
}

.reverse-mode {
  .left-part {
    right: 0;
    border-left: 1px solid $border-color;
  }

  .show-left-part {
    right: auto;
    left: -41px;
  }

  .right-part {
    margin-left: 0px;
    margin-right: $left-part-width;
  }
}

/*******************
 Vertical layout
*******************/

#main-wrapper[data-layout="vertical"] {
  /*Vertical*/
  .topbar .top-navbar .navbar-header {
    //width: $sidebar-width-full;
    transition: 0.2s ease-in;
  }
  /*sidebar type*/
  &[data-sidebartype="full"] {
    .page-wrapper {
      margin-left: $sidebar-width-full;
    }
  }

  &[data-sidebartype="iconbar"] {
    .page-wrapper {
      margin-left: $sidebar-width-iconbar;
    }

    .left-sidebar,
    .topbar .top-navbar .navbar-header,
    .sidebar-nav ul .sidebar-item {
      width: $sidebar-width-iconbar;
    }

    .sidebar-nav {
      ul .sidebar-item {
        .sidebar-link {
          display: block;
          text-align: center;

          i {
            display: block;
            width: auto;
          }
        }
      }
    }
  }

  &[data-sidebartype="overlay"] {
    .topbar .top-navbar .navbar-header {
      width: $sidebar-width-full;
    }

    .left-sidebar {
      left: -$sidebar-width-full;
    }

    &.show-sidebar {
      .left-sidebar {
        left: 0;
      }
    }
  }
  /*Sidebar position*/
  &[data-sidebar-position="fixed"] {
    .topbar .top-navbar .navbar-header {
      position: fixed;
      z-index: 10;
    }

    .left-sidebar {
      position: fixed;
    }
  }
  /*Header position*/
  &[data-header-position="fixed"] {
    .topbar {
      position: fixed;
      width: 100%;
    }

    .page-wrapper {
      padding-top: 76px;
    }
  }
  /*Boxed Layout*/
  &[data-boxed-layout="boxed"] {
    max-width: $boxed-width;
    margin: 0 auto;
    position: relative;
    box-shadow: 1px 0 80px rgba(0, 0, 0, 0.2);
  }

  &[data-boxed-layout="boxed"][data-header-position="fixed"] {
    .topbar {
      max-width: $boxed-width;
    }
  }
}

/*******************
 Vertical responsive layout
*******************/

@include media-breakpoint-up(md) {
  #main-wrapper[data-layout="vertical"] {
    /*Vertical*/
    &[data-sidebartype="full"] {
      .topbar .top-navbar .navbar-header {
        width: $sidebar-width-full;
      }
    }
    /*Sidebar position*/
    &[data-sidebar-position="fixed"][data-sidebartype="full"],
    &[data-sidebar-position="fixed"][data-sidebartype="overlay"] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: 0;
      }
    }

    &[data-sidebar-position="fixed"][data-sidebartype="mini-sidebar"] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: 0;
      }
    }

    &[data-sidebar-position="fixed"][data-sidebartype="iconbar"] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: 0;
      }
    }
    /*Vertical with mini-sidebar*/
    &[data-sidebartype="mini-sidebar"] {
      .topbar .top-navbar .navbar-header {
        width: $sidebar-width-mini;

        .logo-text {
          display: none;
        }
      }
    }
    /*Fixed sidebar with minisidebar*/
    &[data-sidebar-position="fixed"][data-sidebartype="mini-sidebar"] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: $sidebar-width-mini;
      }
    }

    &[data-sidebartype="mini-sidebar"] {
      .page-wrapper {
        margin-left: $sidebar-width-mini;
      }

      .sidebar-nav {
        .hide-menu,
        .has-arrow:after {
          display: none;
        }

        .nav-small-cap {
          justify-content: center;
        }
      }

      .left-sidebar {
        width: $sidebar-width-mini;

        &:hover {
          width: $sidebar-width-full;

          .sidebar-nav {
            .hide-menu,
            .has-arrow:after {
              display: block;
            }

            .nav-small-cap {
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }
}


@include media-breakpoint-up(sm) {
  #main-wrapper[data-layout="vertical"] {
    /*Vertical*/
    &[data-sidebartype="full"] {
      .topbar .top-navbar .navbar-header {
        width: $sidebar-width-full;
      }
    }
    /*Sidebar position*/
    &[data-sidebar-position="fixed"][data-sidebartype="full"],
    &[data-sidebar-position="fixed"][data-sidebartype="overlay"] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: $sidebar-width-full;
      }
    }

    &[data-sidebar-position="fixed"][data-sidebartype="mini-sidebar"] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: $sidebar-width-mini;
      }
    }

    &[data-sidebar-position="fixed"][data-sidebartype="iconbar"] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: $sidebar-width-iconbar;
      }
    }
    /*Vertical with mini-sidebar*/
    &[data-sidebartype="mini-sidebar"] {
      .topbar .top-navbar .navbar-header {
        width: $sidebar-width-mini;

        .logo-text {
          display: none;
        }
      }
    }
    /*Fixed sidebar with minisidebar*/
    &[data-sidebar-position="fixed"][data-sidebartype="mini-sidebar"] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: $sidebar-width-mini;
      }
    }

    &[data-sidebartype="mini-sidebar"] {
      .page-wrapper {
        margin-left: $sidebar-width-mini;
      }

      .sidebar-nav {
        .hide-menu,
        .has-arrow:after {
          display: none;
        }

        .nav-small-cap {
          justify-content: center;
        }
      }

      .left-sidebar {
        width: $sidebar-width-mini;

        &:hover {
          width: $sidebar-width-full;

          .sidebar-nav {
            .hide-menu,
            .has-arrow:after {
              display: block;
            }

            .nav-small-cap {
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }

  .navbar-expand-md {
    .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }

    .navbar-light {
      .navbar-nav {
        .nav-link {
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }

    .topbar {
      .top-navbar {
        .navbar-nav {
          > .nav-item {
            display: block !important;
          }
        }
      }
    }

    header {
      .navbar-expand-md.navbar-light {
        .navbar-header {
          .navbar-brand {
            display: block !important;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xs) {
  #main-wrapper[data-layout="vertical"] {
    /*Vertical*/
    &[data-sidebartype="full"] {
      .topbar .top-navbar .navbar-header {
        width: $sidebar-width-full;
      }
    }
    /*Sidebar position*/
    &[data-sidebar-position="fixed"][data-sidebartype="full"],
    &[data-sidebar-position="fixed"][data-sidebartype="overlay"] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: $sidebar-width-full;
      }
    }

    &[data-sidebar-position="fixed"][data-sidebartype="mini-sidebar"] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: $sidebar-width-mini;
      }
    }

    &[data-sidebar-position="fixed"][data-sidebartype="iconbar"] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: $sidebar-width-iconbar;
      }
    }
    /*Vertical with mini-sidebar*/
    &[data-sidebartype="mini-sidebar"] {
      .topbar .top-navbar .navbar-header {
        width: $sidebar-width-mini;

        .logo-text {
          display: none;
        }
      }
    }
    /*Fixed sidebar with minisidebar*/
    &[data-sidebar-position="fixed"][data-sidebartype="mini-sidebar"] {
      .topbar .top-navbar .navbar-collapse {
        margin-left: 0;
      }
    }

    &[data-sidebartype="mini-sidebar"] {
      .page-wrapper {
        margin-left: $sidebar-width-mini;
      }

      .sidebar-nav {
        .hide-menu,
        .has-arrow:after {
          display: none;
        }

        .nav-small-cap {
          justify-content: center;
        }
      }

      .left-sidebar {
        width: $sidebar-width-mini;

        &:hover {
          width: $sidebar-width-full;

          .sidebar-nav {
            .hide-menu,
            .has-arrow:after {
              display: block;
            }

            .nav-small-cap {
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }

  .navbar-expand-md {
    .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }

    .navbar-light {
      .navbar-nav {
        .nav-link {
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }

    .topbar {
      .top-navbar {
        .navbar-nav {
          > .nav-item {
            display: block !important;
          }
        }
      }
    }

    header {
      .navbar-expand-md.navbar-light {
        .navbar-header {
          .navbar-brand {
            display: block !important;
          }
        }
      }
    }
  }
}

.list-group {
  .list-group-item {
    padding: 6px 15px;

    label {
      margin-bottom: 0;
    }

    pb-switch.ng-invalid + .invalid-tooltip {
      left: 15px !important;
      top: auto !important;
      position: absolute;
      bottom: -10px;
    }
  }
}
