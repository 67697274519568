@import "../../variable";

form {
  .form-group {

    label {
      float: left;
      width: 100%;

      span {
        color: $red;
        font-size: 18px;
        line-height: 0;
      }
    }

    .required {
      color: $red;
      font-size: 18px;
      line-height: 0;
    }

    .form-control {
      border-radius: 0;
      height: 40px;
      border: 1px solid #d6d6d6;
    }

    form .form-group .form-control.ng-dirty.ng-touched.ng-valid {
      border-color: $primary-light;
      border-left: 5px solid $primary-light;
    }

    .form-control.ng-dirty.ng-touched.ng-valid {
      border-color: $primary;
    }
  }

  .form-group small {
    float: left;
    margin-bottom: 15px;
    width: 100%;
  }

  .form-check {
    float: left;
    padding: 0;

    label {
      width: auto;
      margin-right: 10px;
      margin-bottom: 0;
      margin-top: 10px;
      display: inline-block;
      float: left;
    }
  }

  .invalid-feedback {
    display: none;
    width: 100%;
    font-size: 13px;
    color: $red;
    position: absolute;
    bottom: 0;
    margin-bottom: -17px;
  }

  .ng-select .ng-select-container {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-radius: 0;
  }

  .ng-select.ng-select-single .ng-select-container {
    height: 40px;
  }

  .ng-dropdown-panel.ng-select-bottom {
    border-radius: 0;
  }
}

.control-label span {
  color: $red;
  font-size: 18px;
  line-height: 0;
}

/*Custom Switch*/
.custom-switch {
  height: 35px;
  display: inline-block;
  position: relative;

  .btn{
    padding: 0;
  }
}

.required {
  color: $red;
  font-size: 16px;
  margin-bottom: 0;
  margin-bottom: 15px;
}

.input-group-append .btn {
  background-color: $primary;
  border-radius: 0;

  i {
    color: $white;
  }
}

.input-group-text {
  background-color: $primary !important;
  color: $white !important;
  border: none;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.input-group .is-invalid ~ .invalid-tooltip, .input-group .ng-dirty ~ .invalid-tooltip {
  display: block;
}

.form-group .is-invalid ~ .invalid-tooltip, .form-group .ng-dirty ~ .invalid-tooltip {
  display: block;
}


.form-Title {
  float: left;
  width: 100%;
  background-color: $white;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  img {
    height: 60px;
    width: auto;
  }

  h2 {
    margin-top: 12px;
  }
}

ng-select.ng-dirty.ng-touched.ng-valid .ng-select-container {
  border-color: $primary !important;
}

.ng-select {
  float: left;
  width: 100%;
}

.label-inline {
  float: none !important;

  small {
    float: none !important;
    width: auto !important;
  }
}

.page-wrapper {
  margin-bottom: 130px;

  .card-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px 30px 20px 300px;
    z-index: 9;

    .required {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  footer.footer {
    position: fixed;
    bottom: 0;
    padding-left: 252px;
    z-index: 0;
    left: 0;
    background-color: #fff;
    border-top: 1px solid #dee2e6;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.top-7 {
  position: relative;
  top: 7px;
  left: 7px;
}

.form-card {
  .form-group {
    position: relative;

    label {
      display: table-cell;
      height: 40px;
      margin-bottom: 0;
      vertical-align: middle;
      width: 100%;
      float: none;
    }

    .form-control {
      margin-bottom: 0;
    }
  }
}

.pt-10 {
  padding-top: 10px;
}

#counter {
  background-color: grey;
  margin-top: -12px;
  margin-right: 5px;
}

.mb-0 + #counter {
  margin-top: 2px;
}

#counterImpact {
  background-color: grey;
  margin-top: -1px;
  margin-right: 4px;
}

.modal-open .modal-content app-guidance-note-form .formDialogue .card-body .form-group {
  position: relative;
}


.custom-table {

  table {
    background-color: #fff;

    thead {
      tr {
        th {
          text-align: left;
          padding: 3px 10px;
          vertical-align: middle;
          font-weight: 700;
          color: #3e5569;

          &[rowspan="2"] {
            padding: 0 10px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          text-align: left;
          padding: 10px;
          vertical-align: middle;
        }
      }
    }
  }
}

@media (min-width: 1025px) and (max-width: 1599px) {
  .order-1, .order-2, .order-3, .order-4 {
    .form-group {
      .form-control {
        padding: 3px;
      }
    }
  }
}

@media (min-width: 1024px) {

  .total-page-3 {
    margin-top: -71px;
    border-top: 1px solid #6e9bd5;
    padding-top: 15px;
  }
}


@media (min-width: 1200px) {

  .total-page-3 {
    margin-top: -91px;
    border-top: 1px solid #6e9bd5;
    padding-top: 15px;
  }
}

@media (min-width: 1480px) {

  .total-page-3 {
    margin-top: -74px;
    border-top: 1px solid #6e9bd5;
    padding-top: 15px;
  }
}

ui-switch.ng-invalid + .invalid-tooltip {
  display: block;
}

.list-group {
  li {
    list-style: none;
  }
}

.justify-content-between {
  min-height: 50px;
  padding: 7px 15px 5px !important;
  border-radius: 0;
  float: left;
  width: 100%;

  label {
    margin-bottom: 0;
    margin-top: 9px;
  }
}

.mt-2{
  margin-top: 2px !important;
}


.mt-1 {
  margin-top: 1px !important;
}

.page-4-list {
  li {
    .justify-content-between {
      padding: 0 15px !important;

      label {
        margin-top: 0;
      }

      .required-denote {
        margin-top: 9px;
        margin-bottom: 9px;
      }
    }
  }
}

.ng-invalid + .invalid-tooltip {
  display: block;
}

pb-switch.ng-invalid + .invalid-tooltip {
  left: 0;
  top: 30px !important;
}

.tooltip-right .invalid-tooltip {
  left: auto !important;
  right: 0;
}


.tooltip-group {
  position: relative;

  .required {
    margin-top: 2px;
    white-space: nowrap;
    position: absolute;
  }
}
